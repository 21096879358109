import React from 'react';

export function DonationReviewTitle() {
  return (
    <div className="text-3xl text-center sm:text-start font-extraBold md:text-5xl">
      Loved by <span className="text-primary">you</span>
    </div>
  );
}

export function AppReviewTitle() {
  return (
    <div className="text-3xl text-center sm:text-start font-extraBold md:text-5xl">
      What Our <span className="text-primary">Users</span> Had To Say?
    </div>
  );
}
export function HomePageReviewTitle() {
  return (
    <h1 className="text-3xl text-center sm:text-start font-extraBold md:text-5xl">
      What our Users had to Say
    </h1>
  );
}

export function RamadanImpactPageReviewTitle() {
  return (
    <div className="tracking-[-0.3px] text-3xl text-center sm:text-start font-extraBold md:text-5xl">
      Loved By Our Users
    </div>
  );
}
