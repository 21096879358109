import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../components/layout.js';
import Markdown from 'markdown-to-jsx';

import { checkLocation, cn } from '../../utils.js';
import { CommonContext } from '../../context.js';
import {
  PolygonPrimary,
  QuestionMarkCircle,
} from '../../custom-icons/index.js';
import Reviews from '../../components/Reviews';
import { DonationReviewTitle } from '../../components/ReviewTitle.js';
import DonationForm from '../../components/DonationForm.js';
import AchievementsSection from '../../components/AchievementsSection.js';
import { DonationMediumList } from '../../components/DonationMediumList';
const QuestionsList = ({ questions }) => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  return (
    <div className="p-6 shadow bg-neutral-0 rounded-2xl">
      <div className="flex gap-2 mb-4 leading-6 font-semiBold">
        <QuestionMarkCircle className="w-6 h-6 text-neutral-5" />
        <p> Questions?</p>
      </div>
      <div className="p-0 text-sm leading-6 font-regular markdown">
        {questions.map((item) => {
          return (
            <React.Fragment key={item.title}>
              <div
                className="mb-4 cursor-pointer"
                onClick={() => {
                  if (item.title === expandedQuestion)
                    setExpandedQuestion(null);
                  else setExpandedQuestion(item.title);
                }}
              >
                <PolygonPrimary
                  className={cn(
                    'inline-block mr-3 w-3 h-3',
                    item.title === expandedQuestion
                      ? 'transform rotate-90'
                      : '',
                  )}
                />
                {item.title}
              </div>
              {item.title === expandedQuestion ? (
                <div className="p-4 pt-1 pb-1 mt-2 mb-4 rounded-2xl bg-neutral-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  />
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

QuestionsList.propTypes = {
  questions: PropTypes.array.isRequired,
};

export const query = graphql`
  query DonateDataQuery {
    allWpFaq(
      filter: {
        faq: { category: { eq: "Donation" }, showondonatepage: { eq: true } }
      }
    ) {
      nodes {
        title
        content
        faq {
          category
        }
      }
    }
    allDonate {
      nodes {
        legal_info
        donate_heading
        donate_heading_2
        donate_text
      }
    }
    allUserReviews {
      nodes {
        reviewer
        stars
        reviewText
        avatar
      }
    }
    allAchievements {
      nodes {
        icon
        title
        description
      }
    }
  }
`;
const DonatePage = ({ location, data }) => {
  const achievements = data.allAchievements.nodes;
  const donationQa = data.allWpFaq.nodes;
  const reviews = data.allUserReviews.nodes;
  data = data.allDonate.nodes[0];
  const [donorboxUrl, setDonorboxUrl] = useState('');

  const generateDonorboxUrl = (currency) => {
    const baseUrl =
      'https://donorbox.org/embed/greentechapps?hide_donation_meter=true';
    const currencyParam = currency ? `&currency=${currency.toLowerCase()}` : '';
    const searchParams = location.search.replace('?', '&');
    return `${baseUrl}${currencyParam}${searchParams}`;
  };

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const location = checkLocation();
        const currencyCode = location.currency_code || 'GBP';
        localStorage.setItem('currency', currencyCode);
        const newDonorboxUrl = generateDonorboxUrl(currencyCode);
        setDonorboxUrl(newDonorboxUrl);
      } catch (error) {
        const newDonorboxUrl = generateDonorboxUrl();
        setDonorboxUrl(newDonorboxUrl);
      }
    };

    const currency = localStorage.getItem('currency');
    if (currency) {
      const newDonorboxUrl = generateDonorboxUrl(currency);
      setDonorboxUrl(newDonorboxUrl);
    } else {
      fetchLocationData();
    }
  }, []);

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        title="Donate"
        keywords={['donate', 'donation', 'islam', 'help', 'fund']}
        description={data.donate_text}
        location={location}
      >
        <section className="w-full">
          <div className="w-full bg-neutral-1">
            <div className="w-full max-w-6xl px-4 pt-6 mx-auto sm:px-6 sm:pt-10">
              <div className="p-4 px-4 shadow-lg bg-neutral-0 rounded-2xl md:p-8 sm:px-6 sm:pt-10 sm:pb-6">
                <div className="px-4 text-xl text-center md:text-3xl md:px-12">
                  <Markdown>{data.donate_heading}</Markdown>
                  <p className="mt-1 font-semiBold text-primary">
                    {data.donate_heading_2}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full max-w-6xl px-4 pt-6 pb-6 mx-auto lg:grid lg:grid-cols-7 lg:gap-4 sm:px-6">
              <div className="p-4 mb-6 shadow-lg bg-neutral-0 rounded-2xl sm:p-6 lg:p-8 lg:col-span-5">
                <DonationForm donorboxUrl={donorboxUrl} />
              </div>
              <div className="space-y-6 lg:col-span-2">
                <DonationMediumList />
                <QuestionsList questions={donationQa} />
              </div>
            </div>

            <AchievementsSection
              achievements={achievements}
              title="Impact of Your Support"
            />
          </div>
          {reviews.length ? (
            <Reviews reviews={reviews}>
              <DonationReviewTitle />
            </Reviews>
          ) : null}
        </section>
      </Layout>
    </CommonContext.Provider>
  );
};

DonatePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default DonatePage;
