import React from 'react';
import { DonationMedium } from './DonationMedium';
export function DonationMediumList() {
  const donationMediums = [
    {
      iconSrc: '/icons/paypal-icon.svg',
      iconAltText:'Icon for Paypal Giving Fund',
      title: 'Paypal Giving Fund',
      text: 'Support us through Paypal Giving Fund',
      uri: 'https://www.paypal.com/gb/fundraiser/charity/3622335',
    },
    {
      iconSrc: '/icons/bank-transfer-icon.svg',
      iconAltText:'Icon for Bank Transfer',
      title: 'Bank Transfer',
      text: 'Donate via Bank or Wise Transfer',
      uri: 'https://gtaf.org/support/i-want-to-donate-monthly-how-can-i-setup-a-standing-order/',
    },
    {
      iconSrc: '/icons/signx-icon.svg',
      iconAltText:'Icon for SignX',
      title: 'SingX',
      text: 'Send your donations via SingX',
      uri: 'https://gtaf.org/support/can-i-donate-to-gtaf-using-singx/',
    },
  ];
  return (
    <div className="space-y-4">
      <p className="font-bold text-xl ">Other Ways To Donate</p>
      {donationMediums.map((medium, index) => (
        <DonationMedium
          key={index}
          iconSrc={medium.iconSrc}
          iconAltText={medium.iconAltText}
          title={medium.title}
          text={medium.text}
          uri={medium.uri}
        />
      ))}
    </div>
  );
}
