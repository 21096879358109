import React from 'react';
import { ArrowRightIcon } from '../custom-icons';
import PropTypes from 'prop-types';
export function DonationMedium({ iconSrc, iconAltText, title, text, uri }) {
  return (
    <a href={uri} target="_blank" rel="noreferrer">
      <div className="group p-4 shadow bg-neutral-0 rounded-2xl !mt-3 transition-all duration-300 ease-in-out">
        <div className="flex h-full lg:flex-row gap-2">
          <div className="flex-shrink-0 mx-2">
            <img className="w-14 h-14" src={iconSrc} alt={iconAltText} />
          </div>

          <div>
            <p className="font-semiBold text-sm">{title}</p>
            <p className="text-xs text-neutral-7">{text}</p>
          </div>
        </div>
        <div className="flex justify-end mt-2">
          <ArrowRightIcon className="text-primary duration-200 transform group-hover:translate-x-1.5" />
        </div>
      </div>
    </a>
  );
}

DonationMedium.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  iconAltText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};
